.profile-options-page {
  .profile-form {
    width: 480px;
    input {
      width: 240px;
    }
    h3 {
      height: 42px;
      line-height: 42px;
    }
  }
}

.unity-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .loading-overlay {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .progress-bar {
      width: 100%;
      background-color: transparent;
      border: #354ac9 1px solid;
      border-radius: 5px;
      margin-top: 10px;
      height: 20px;
      position: relative;

      .progress-bar-fill {
        background-color: #354ac9;
        width: 0;
        border-radius: 5px;
        transition: width 0.3s ease-in-out;
        height: 100%;
      }

      .progress-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffff;
        text-shadow: #fff;
        font-weight: bold;
        text-shadow: 
          -1px -1px 0 #000000,  
          1px -1px 0 #000000,
          -1px 1px 0 #000000,
          1px 1px 0 #000000; 
      }
    }

    p {
      color: #fff;
    }
  }
}

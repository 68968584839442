.my-students-page {
  background-color: #fafafa;
  .my-students-list-container {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .my-students-grid {
    display: grid;
    gap: 24px;
    grid-auto-rows: min-content;
    row-gap: 0px;

    &.one-col {
      grid-template-columns: auto;
    }

    &.two-col {
      grid-template-columns: auto auto;
    }

    &.three-col {
      grid-template-columns: auto auto auto;
    }
  }
}
.table-action-buttons {
  a {
    color: unset;
    font-weight: 600;
  }

  .table-more-button {
    margin-left: 5px;
    cursor: pointer;
  }
}
.text-button {
  display: inline-block;
  cursor: pointer;
  background-color: #f1f1f1;
  padding: 8px 6px;
  border: 1px solid #d1d1d1;
  margin: 4px 2px;
}
.view {
  background-color: #1890ff;
  padding: 8px 10px;
  color: white !important;
}
.total_students {
  color: gray;
}
.btn-color-danger{
  background-color: #FF0000 !important;
}

.bank-request-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  color: black;
  box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.2);
  position: relative;
  border-radius: 4px;
  a {
    color: black;
  }
  .icon-close {
    background-color: #d25751;
  }

  .icon-add {
    background-color: #afe270;
  }
  .error {
    color: red;
  }
}

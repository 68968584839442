.class-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  color: black;
  box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.2);
  position: relative;
  border-radius: 4px;
  .icon-edit {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #eecb54;
  }
  a {
    color: black;
  }

  &.selected {
    background-color: #fdfde1;
  }

  h3 span {
    font-size: 16px;
    font-weight: normal;
    padding: 8px 16px;
    background-color: #e8ebed;
    border-radius: 4px;
  }
}

.class-form {
  overflow: hidden;
  display: flex;
  .students-grid-container {
    overflow-y: scroll;
    border-top: 1px solid black;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .students-grid {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 0;
    grid-auto-rows: min-content;
    border-left: 1px solid black;
  }
}

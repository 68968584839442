.signup-page {
  height: 100vh;
  .signup-header {
    width: 640px;
  }
  form {
    width: 640px;
    .user-type {
      &__control {
        height: 50px;
      }
    }
  }
  input {
    padding: 16px 24px;
    border-radius: 8px;
    border: none;
    background-color: #fafafa;
  }
}

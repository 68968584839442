p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.error-message {
  color: #e66767;
}

.bold {
  font-weight: 500;
}

.font-black {
  color: black;
}
.font-gray {
  color: rgba(133, 132, 132, 0.5);
}
.text-center {
  text-align: center;
}

.link {
  color: #0c59cf;
}

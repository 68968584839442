.nav-menu-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  &.fade-out {
    pointer-events: none;
  }
}

.nav-menu {
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  padding: 48px;
  height: 100vh;
  width: 200px;
  display: flex;
  flex-direction: column;

  p,
  a {
    color: black;
    font-weight: 500;
    font-size: 1.2em;
    &:hover {
      color: #0c59cf;
      cursor: pointer;
    }
  }
}

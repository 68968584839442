.find-adventures-page {
  background-color: #fafafa;

  .adventures-list-filter {
    width: 240px;
  }

  .adventure-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    gap: 24px;
  }
}

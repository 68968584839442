.games-grid {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 24px;
  grid-auto-rows: min-content;

  &.small {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

.aim-list {
  display: grid;
  gap: 24px;
  grid-template-columns: auto auto;
  grid-auto-rows: min-content;
}

.word-suggestion {
  display: flex;
  align-items: center;
  img {
    width: 64px;
    height: 64px;
    object-fit: contain;
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 320px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 51px;
  width: 360px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 380px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    background-color: #fafafa;
  }
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd;
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

.words-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  grid-auto-rows: min-content;
}

.teacher-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  color: black;
  box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.2);
  position: relative;
  border-radius: 4px;
  min-height: 48px;

  &__type {
    color: #67bc58;
    border: 2px solid #89d572;
    padding: 2px 32px;
    border-radius: 20px;
  }

  .icon-edit {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #eecb54;
  }
  a {
    color: black;
  }

  &.selected {
    background-color: #fdfde1;
  }
}

@import './icons.scss';
@import './colors.scss';
@import './pages.scss';
@import './typography.scss';
@import './padding.scss';
@import './margin.scss';
@import './flex.scss';
@import './inputs.scss';
@import './animations.scss';
@import './card.scss';
@import './grid.scss';
@import './table.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  padding: 0;
}

.circle {
  border-radius: 50%;
}

.list-container {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-vertical {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}

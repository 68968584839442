.btn {
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn-wide {
  padding-left: 48px;
  padding-right: 48px;
}

.btn-short {
  padding: 5px 5px;
  background-color: #2552cc;
  color: white;
}

.btn-primary {
  background-color: #2552cc;
  color: white;
  &.disabled {
    background-color: rgba($color: #2552cc, $alpha: 0.5);
    cursor: default;
  }
}

.btn-danger {
  background-color: #cc2525;
  color: white;
  &.disabled {
    background-color: rgba($color: #cc2525, $alpha: 0.5);
    cursor: default;
  }
}

.btn-success {
  background-color: #00a849;
  color: white;

  &.disabled {
    background-color: rgba($color: #00a849, $alpha: 0.5);
    cursor: default;
  }
}

.btn-danger {
  background-color: #e66767;
  color: white;
}

.btn-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-refresh {
  border: 1px solid #fafafa;
  background: none;
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    border-color: black;
    background-color: #ebebeb;
  }

  &:focus {
    outline: none;
  }
}

.btn-link {
  color: #2552cc;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}

.aim-list-item {
  padding: 24px 24px 16px;
  color: black;
  box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.2);
  position: relative;
  border-radius: 4px;
  min-height: 48px;
  cursor: pointer;
  .aim-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  &.selected {
    background-color: #fdfde1;
  }
}

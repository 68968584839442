.pricing-table-page {
  .columns {
    float: left;
    width: 33.3%;
    box-sizing: border-box;
  }

  .price-card {
    list-style-type: none;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    li {
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
    }

    .header {
      background-color: #2552cc;
      color: white;
      font-size: 25px;
      box-sizing: border-box;
    }
    .price {
      background-color: #e6e6e6;
      font-size: 20px;
      .plan {
        font-size: 18px;
      }
    }

    .price-card:hover {
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    }
  }

  @media only screen and (max-width: 600px) {
    .columns {
      width: 100%;
    }
  }
}

.go-page {
  .adventure-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    gap: 24px;
  }
  .go-choose-class {
    .class-list-item {
      cursor: pointer;
    }
    .btn-primary {
      align-self: flex-end;
    }
  }

  .go-choose-adventure {
    .adventures-list-filter {
      width: 240px;
    }
    .adventure-list-item {
      cursor: pointer;
    }
  }

  .go-success {
    &-card {
      display: flex;
      flex-direction: column;
      padding: 24px;
      color: black;
      box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.2);
      position: relative;
      border-radius: 4px;
      min-height: 48px;
    }
  }
}

.tag {
  position: absolute;
  border: 2px solid #0c59cf;
  color: #0c59cf;
  padding: 4px 8px;
  border-radius: 4px;
}

.table-action-buttons {
  a {
    color: unset;
    font-weight: 600;
  }

  .table-more-button {
    margin-left: 5px;
    cursor: pointer;
  }
}
.bundles-page {
  background-color: #fafafa;

  .bundle-list-filter {
    width: 120px;
  }

  .bundle-grid {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 24px;
    grid-auto-rows: min-content;

    .card {
      height: 180px;
    }
  }
}
.text-buttons {
  display: inline-block;
  cursor: pointer;
  background-color: #f1f1f1;
  padding: 8px 14px !important;
  border: 1px solid #d1d1d1;
  margin: 4px 2px !important;
}
.view {
  background-color: #1890ff;
  padding: 8px 20px;
  border-radius: 20px;
}

.light-blue{
  color:#91d5ff !important;
}

.primary{
  color: #1890ff;
}

.table-row-light {
  background-color: #e0e6e9;
}

.table-row-dark {
  background-color:white;
}

.margin{
  margin-bottom: 20px;
}

.mg-top{
  margin-top: 10px
}

.input-icon{
  padding-top: 18px;
}

.input{
  padding: 20px;
}

.blue-heading{
  color: '#1890ff' !important;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fcce36;
  opacity: 0.9;
  // border-color: rgba(0, 0, 0, 0.03);
}

.text-heading {
  color: #2552cc;
}

.summary-btns{
  padding-top: 3%;
}

.ant-btn-primary{
    background-color: #2552cc !important;
    border-color: #2552cc !important;  
}

.sidebar-analytics {
  background-color: #fafafa;
  border: 1px solid black;
  border-top: none;
  a {
    color: black;
  }

  &-item {
    display: flex;
    text-align: center;
    align-items: center;
    font-weight: 500;
    height: 34px;
    padding: 12px 24px 12px 24px;
    box-shadow: 0px 5px 5px rgba($color: #000000, $alpha: 0.1);
    border-left: none;
    border-right: none;
    border: 1px solid #fafafa;
    cursor: pointer;
    background-color: #fafafa;
    color: black;

    &:hover,
    &.selected {
      border-color: black;
      background-color: #ebebeb;
    }
  }
}

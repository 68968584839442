.card {
  padding: 16px;
  box-shadow: 0px 5px 10px rgba($color: #000000, $alpha: 0.1);
  border-radius: 4px;
  background-color: #fafafa;
  border: 1px solid #fafafa;

  &.clickable {
    &:hover,
    &.selected {
      border-color: black;
    }
  }

  &-nav {
    height: 144px;
  }
}

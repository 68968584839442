.students-bar {
  width: 240px;
  border-left: 1px solid black;
  .students-list-container {
    overflow-y: scroll;
    margin-bottom: 16px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.bg-gray {
  background-color: #d8d8d8;
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
}

.icon-24 {
  width: 24px;
  height: 24px;
  background-size: 24px;
}

.icon-48 {
  width: 48px;
  height: 48px;
  background-size: 48px;
}

.icon-menu {
  background-image: url("../images/icons/menu.svg");
}

.icon-search-b {
  background-image: url("../images/icons/search-black.svg");
}

.icon-close {
  background-image: url("../images/icons/close.svg");
}

.icon-close-b {
  background-image: url("../images/icons/close-black.svg");
}

.icon-add {
  background-image: url("../images/icons/add.svg");
}

.icon-add-b {
  background-image: url("../images/icons/add-black.svg");
}

.icon-edit {
  background-image: url("../images/icons/edit.svg");
}

.icon-delete {
  background-image: url("../images/icons/delete.svg");
}

.icon-sound-b {
  background-image: url("../images/icons/sound.svg");
}

.icon-locked {
  background-image: url("../images/icons/lock.svg");
}

.icon-locked-b {
  background-image: url("../images/icons/close-black.svg");
}

.icon-refresh {
  background-image: url("../images/icons/refresh.svg");
}

.icon-refresh-b {
  background-image: url("../images/icons/refresh-black.svg");
}

.icon-check {
  background-image: url("../images/icons/check.svg");
}

@import "../../styles/colors.scss";

.nav-bar {
  height: 80px;
  padding: 0 24px;
  background-color: $color-primary;
  display: flex;
  align-items: center;
  color: white;
  justify-content: space-between;
  overflow-x: hidden;
  h3 {
    align-self: center;
  }
  .school-color, a {
    color: white !important;
  }
  .icon {
    cursor: pointer;
  }
}

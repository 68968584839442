.checkout-session-page {
  .seats-field {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
  }
  .error {
    color: red;
    align-self: center;
  }
  .package-list {
    .package-info {
      border-bottom: 1px solid #e6e6e6;
      padding: 20px;
      text-align: center;
      font-size: 20px;
    }
  }
}
.transfer-message {
  color: gray;
}

.table {
  border: none;
  text-align: center;
  width: 100%;

  .table-data {
    padding: 10px;
  }

  .table-header {
    border: 1px solid #efefef;
    background-color: #0c59cf;
    color: white;
    padding: 10px;
  }
  .table-row {
    height: 50px;
  }
  .table-row:nth-child(even) {
    background-color: #e6e6e6;
  }
}

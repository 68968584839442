.fade-in {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.slide-in__right {
  transform: translate(0px, 0);
  transition: all 0.2s ease-in-out;
}

.slide-out__right {
  transform: translate(100%, 0);
  transition: all 0.2s ease-in-out;
}

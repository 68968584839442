.box {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  margin-bottom: 16px;
  padding: 45px;
  padding-left: 0px;
  margin-left: 10px;

  .card {
    padding: 16px;
    box-shadow: 0px 10px 20px rgba($color: #000000, $alpha: 0.2);
    border-radius: 20px;
    background-color: #fafafa;
    border: 1px solid #fafafa;

    &.clickable {
      &:hover,
      &.selected {
        border: 2px solid #0e59cf;
        box-shadow: 0px 0px 15px rgba($color: #0e59cf, $alpha: 0.5);
        transform: scale(1.05);
        transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
      }
    }

    &-nav {
      height: 300px;
      min-width: 250px;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;

      .font-black {
        color: black;
      }
    }
  }
}

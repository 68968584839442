.input {
  outline: none;
  border: 1px solid black;
  height: 32px;
  border-radius: 18px;
  &-icon {
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: 8px 6px;
    padding-left: 36px;
    padding-right: 12px;
  }
}

.form-input {
  height: 32px;
  padding: 4px 16px;
  border-radius: 4px;
  border: 1px solid black;
  &:focus {
    border-color: #2552cc;
  }
}

.students-grid-item {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  .btn-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  &:hover {
    text-decoration: underline;
  }
}

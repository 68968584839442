.student-bar-list-item {
  padding: 12px;
  border: 1px solid black;
  border-radius: 16px;
  background-color: white;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
    border-radius: 24px;
  }
}

.login-page {
  height: 100vh;

  form {
    width: 320px;
  }
  input {
    padding: 16px 24px;
    border-radius: 8px;
    border: none;
    background-color: #fafafa;
  }
  .error {
    max-width: "320px";
  }
}

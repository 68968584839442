.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  padding-bottom: 20px;
  width: 100%;
  .inner-container {
    padding-top: 60px;
    padding-bottom: 10px;
    overflow-y: auto;
    max-height: 500px;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #0e59cf;
  color: white;
}

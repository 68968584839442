.sidebar {
  width: 240px;
  background-color: #fafafa;
  border: 1px solid black;
  border-top: none;
  a {
    color: black;
  }
  .avatar {
    width: 56px;
    height: 56px;
    border: 1px solid black;
    border-radius: 28px;
  }

  &-item {
    display: flex;
    align-items: center;
    font-weight: 500;
    height: 34px;
    padding-left: 48px;
    box-shadow: 0px 5px 5px rgba($color: #000000, $alpha: 0.1);
    border: 1px solid #fafafa;
    border-left: none;
    border-right: none;
    cursor: pointer;
    background-color: #fafafa;
    color: black;

    &:hover,
    &.selected {
      border-color: black;
      background-color: #ebebeb;
    }
  }
}
